import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-form';

const NestedCollection = (props) => {
  const { children, field, validate } = props;
  const { FieldScope, meta: { error }, pushValue, removeValue, setFieldValue, setValue, value: values } = useField(String(field), { validate });
  const dragValues = useCallback((oldIndex, newIndex) => {
    if (oldIndex !== newIndex && newIndex < values.length && newIndex >= 0) {
      const draggedValues = [...values];
      const value = draggedValues[oldIndex];
      draggedValues.splice(oldIndex, 1);
      draggedValues.splice(newIndex, 0, value);
      setValue(draggedValues);
      return draggedValues;
    }
    return values;
  }, [setValue, values]);
  const unshiftValue = useCallback((newValue) => {
    setValue([newValue, ...values]);
  }, [setValue, values]);
  if (!children) {
    return null;
  }
  return (
    <FieldScope>
      {typeof children === 'function' ? children({
        dragValues,
        error,
        pushValue,
        removeValue,
        setFieldValue,
        setValue,
        unshiftValue,
        values,
      }) : children}
    </FieldScope>
  );
};

NestedCollection.defaultProps = {
  validate: null,
};

NestedCollection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  field: PropTypes.string.isRequired,
  validate: PropTypes.func,
};

export default NestedCollection;
