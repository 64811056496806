import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@popmenu/common-ui';

import { NestedField } from '../../../../admin/shared/forms/BasicForm';
import ModifierGroupOptions from '../ModifierGroupOptions';

const SelectedModifiers = ({ calculateOutOfStock, getExtraTitle, modernLayout, modifierGroups }) => (
  <React.Fragment>
    {modifierGroups.length > 0 && (
      <Box>
        <NestedField field="selectedModifierGroups">
          {modifierGroups.map((modifierGroup, i) => (
            <NestedField key={modifierGroup.id} field={i}>
              <ModifierGroupOptions
                calculateOutOfStock={calculateOutOfStock}
                getExtraTitle={getExtraTitle}
                modifierGroup={modifierGroup}
                modernLayout={modernLayout}
              />
            </NestedField>
          ))}
        </NestedField>
      </Box>
    )}
  </React.Fragment>
);

SelectedModifiers.propTypes = {
  calculateOutOfStock: PropTypes.func.isRequired,
  getExtraTitle: PropTypes.func.isRequired,
};

export default SelectedModifiers;
