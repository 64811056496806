import React, { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';
import { Box, Typography } from '@popmenu/common-ui';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useLazyQuery, useQuery } from '~/lazy_apollo/client';

import { getApolloClient } from '~/lazy_apollo';
import { createEvent } from '~/utils/eventable';
import { findElement } from '../../../../utils/dom';
import { trackFacebookEvent } from '../../../../utils/fbq';
import {
  textLengthValidator,
  isTextLengthWithinRange,
  minOneValidator,
  notBlankValidator,
  minMaxValidator,
} from '../../../../utils/forms';
import { formatCurrency } from '../../../../utils/currency';
import { nl2br } from '../../../../utils/react';
import { toInt } from '../../../../utils/numbers';
import { withIntl } from '../../../../utils/withIntl';
import { withRestaurant } from '../../../../utils/withRestaurant';
import { withSnackbar } from '../../../../utils/withSnackbar';
import { classNames, withStyles } from '../../../../utils/withStyles';
import { withWindowSizeContext } from '../../../../shared/WindowSizeProvider';
import menuItemCartFormStyles from './styles';
import { closeAddToCartModal, openAddToCartModal } from '../../../../shared/DishActions';
import { handleNotFound } from '../MenuItemCartHelpers';
import menuItemByLocationAndFulfillmentTypeQuery from '../../../../libs/gql/queries/menus/menuItemByLocationAndFulfillmentTypeQuery.gql';
import menuItemModifierGroupsQuery from '../../../../libs/gql/queries/menus/menuItemModifierGroupsQuery.gql';
import sessionWithMenuItemCartQuery from '../../../../libs/gql/queries/menu_item_carts/sessionWithMenuItemCartQuery.gql';

import BasicForm, { SubmitGroup, TextAreaGroup, TextFieldGroup } from '../../../../admin/shared/forms/BasicForm';
import CustomImg from '../../../../shared/CustomImg';
import Divider from '../../../../admin/shared/forms/Divider';

import Grid from '../../../../shared/Grid';
import RadioGroup from '../../../../admin/shared/forms/RadioGroup';
import { useLoadedModifierGroups } from '../LoadedModifierGroupsProvider';
import SelectedExtras from '../SelectedExtras';
import SelectedModifiers from '../SelectedModifiers';
import { AH } from '../../../shared/AccessibleHeading';
import { setSelectedMenuItem } from '../../../../shared/MenuItemCartActions';
import Loading from '../../../../shared/Loading';
import calculateMenuItemSubtotal from '../../../shared/utils/CalculateMenuItemSubtotal';

const formatExtraName = (name, price) => (price ? `${name} (+ ${price})` : name);

const getSelectedExtras = (extraGroup, selectedExtraGroups) => {
  if (!extraGroup || !selectedExtraGroups) {
    return [];
  }
  const selectedExtraGroup = selectedExtraGroups.find(seg => seg && seg.menuItemExtraGroupId === extraGroup.id);
  return extraGroup.extras.map((extra) => {
    const selectedExtra = selectedExtraGroup && selectedExtraGroup.selectedExtras.find(se => se && se.extraId === extra.id);
    return ({
      extraId: extra.id,
      quantity: (selectedExtra ? toInt(selectedExtra.quantity) : (extra.isDefault ? 1 : 0)) || 0,
    });
  });
};

const getSelectedModifiers = (modifierGroup, selectedModifierGroups) => {
  if (!modifierGroup || !selectedModifierGroups) {
    return [];
  }
  const selectedModifierGroup = selectedModifierGroups.find(smg => smg && smg.menuItemModifierGroupId === modifierGroup.id);
  return modifierGroup.modifiers.map((modifier) => {
    const selectedModifier = selectedModifierGroup && selectedModifierGroup.selectedModifiers.find(sm => sm && sm.menuItemModifierId === modifier.id);
    return ({
      id: selectedModifier?.id,
      menuItemModifierId: modifier.id,
      quantity: (selectedModifier ? toInt(selectedModifier.quantity) : (modifier.isDefault ? 1 : 0)) || 0,
    });
  });
};

const getSelectedSharedExtras = (sharedExtraGroup, selectedSharedExtraGroups) => {
  if (!sharedExtraGroup || !selectedSharedExtraGroups) {
    return [];
  }
  const selectedSharedExtraGroup = selectedSharedExtraGroups.find(sseg => sseg && sseg.sharedExtraGroupId === sharedExtraGroup.id);
  return sharedExtraGroup.sharedExtraGroup.extras.map((extra) => {
    const selectedExtra = selectedSharedExtraGroup && selectedSharedExtraGroup.selectedExtras.find(se => se && se.extraId === extra.id);
    return ({
      extraId: extra.id,
      quantity: (selectedExtra ? toInt(selectedExtra.quantity) : (extra.isDefault ? 1 : 0)) || 0,
    });
  });
};

export const isValidModifierGroupQuantity = (selectedModifierGroups, loadedModifierGroups) => {
  // Check each selected modifier group to compare the number of selected items vs the min and max selection count
  for (let i = 0; i < selectedModifierGroups.length; i += 1) {
    // Find modifier group (from which min and mix selection counts come) through queried modifier groups
    const modifierGroup = loadedModifierGroups.find(mg => mg.id === selectedModifierGroups[i].menuItemModifierGroupId || selectedModifierGroups[i].selectedModifiers?.find(sm => sm.selectedModifierGroups?.find(smg => smg.menuItemModifierGroupId === mg.id)));
    const modifiersCount = selectedModifierGroups[i].selectedModifiers.length;

    if (modifierGroup && modifiersCount > 0) {
      const { minSelectionsCount, maxSelectionsCount } = modifierGroup;
      // Get the quantity of each selected modifier for this iteration and add them together
      const selectedModifiersQuantities = selectedModifierGroups[i].selectedModifiers.map(sm => sm.quantity).reduce((acc, cur) => parseInt(acc, 10) + parseInt(cur, 10));
      const doubleCheck = selectedModifiersQuantities;
      let isValidNestedModifierGroupQuantity = true;
      // For each modifier group under each modifier recursively look through modifier options and validate selection counts if it's selected
      for (let j = 0; j < modifiersCount; j += 1) {
        const nestedSelectedModifierGroups = selectedModifierGroups[i].selectedModifiers[j].selectedModifierGroups;
        if (nestedSelectedModifierGroups && nestedSelectedModifierGroups.length > 0 && selectedModifierGroups[i].selectedModifiers[j].quantity > 0) {
          isValidNestedModifierGroupQuantity = isValidModifierGroupQuantity(nestedSelectedModifierGroups, loadedModifierGroups);
        }
      }

      const isNotValidMaxSelectionCount = !!maxSelectionsCount && Math.floor(selectedModifiersQuantities) > maxSelectionsCount;

      // If the quantities total sum of selected modifiers is under the min selection count or over the max selection count return true
      if (
        Math.floor(selectedModifiersQuantities) < minSelectionsCount ||
        isNotValidMaxSelectionCount ||
        selectedModifiersQuantities !== doubleCheck ||
        !isValidNestedModifierGroupQuantity
      ) {
        return false;
      }
    }
  }

  return true;
};

const isValidQuantity = (value, menuItem, isNestedModifiersEnabled, loadedModifierGroups) => {
  if (value.quantity < 1) {
    return false;
  }

  if (isNestedModifiersEnabled) {
    const { selectedModifierGroups } = value;

    return isValidModifierGroupQuantity(selectedModifierGroups, loadedModifierGroups);
  } else {
    const { selectedExtraGroups, selectedSharedExtraGroups } = value;

    for (let i = 0; i < selectedExtraGroups.length; i += 1) {
      const extraGroup = menuItem.extraGroups.find(eg => eg.id === selectedExtraGroups[i].menuItemExtraGroupId);
      if (extraGroup && selectedExtraGroups[i].selectedExtras.length > 0) {
        // Get the quantity of each selected extras for this iteration and add them together
        const selectedExtrasQuantities = selectedExtraGroups[i].selectedExtras.map(se => se.quantity).reduce((acc, cur) => parseInt(acc, 10) + parseInt(cur, 10));
        const doubleCheck = selectedExtrasQuantities;
        // If the quantities total sum of selected extras is under the min selection count or over the max selection count return true
        if (Math.floor(selectedExtrasQuantities) < extraGroup.minSelectionsCount ||
          Math.floor(selectedExtrasQuantities) > extraGroup.maxSelectionsCount ||
          selectedExtrasQuantities !== doubleCheck) {
          return false;
        }
      }
    }

    // Check each selected shared extra group to compare the number of selected items vs the min and max selection count
    for (let i = 0; i < selectedSharedExtraGroups.length; i += 1) {
      const sharedExtraGroup = menuItem.sharedExtraGroups.find(seg => seg.id === selectedSharedExtraGroups[i].menuItemSharedExtraGroupId);
      if (sharedExtraGroup && selectedSharedExtraGroups[i].selectedExtras.length > 0) {
        const selectedSharedExtrasQuantities = selectedSharedExtraGroups[i].selectedExtras.map(se => se.quantity).reduce((acc, cur) => parseInt(acc, 10) + parseInt(cur, 10));
        const doubleCheck = selectedSharedExtrasQuantities;

        if (selectedSharedExtrasQuantities < sharedExtraGroup.minSelectionsCount ||
          selectedSharedExtrasQuantities > sharedExtraGroup.maxSelectionsCount ||
          selectedSharedExtrasQuantities !== doubleCheck) {
          return false;
        }
      }
    }
  }

  return true;
};

const isValidSpecialInstructions = (
  message,
  isNcrSilverPayment,
  menuItemCartSpecialInstructionsMaxLength,
) => (isNcrSilverPayment ? isTextLengthWithinRange(message, { max: menuItemCartSpecialInstructionsMaxLength, min: 0 }) : true);

const SelectedItemForm = (props) => {
  const { menuItem } = props;
  const client = useApolloClient();
  const dispatch = useDispatch();
  const cartLocationId = useSelector(state => state.menuItemCart.menuItemCartLocationId);
  const cartFulfillmentType = useSelector(state => state.menuItemCart.menuItemCartFulfillmentType);
  const discrepancyMenuItemIds = useSelector(state => state.dishes.discrepancyMenuItemIds);
  const remindToTry = useSelector(state => state.dishes.remindToTry);
  const featuredSectionData = useSelector(state => state.featuredSection);
  const { loadedModifierGroups } = useLoadedModifierGroups();
  const titleElement = findElement('.pm-menu-item');

  const [isStuck, setIsStuck] = useState(false);
  const [initialOffsetTop, setInitialOffsetTop] = useState(titleElement ? titleElement.offsetTop : null);

  const { restaurant, windowSize, menuItemCartSpecialInstructionsMaxLength } = props;
  const { isNestedModifiersEnabled } = restaurant;
  const cartIsNewLocation = cartLocationId && cartLocationId !== menuItem.section.menu.locationId;
  const isOrderingSpecialInstructionsEnabled = menuItem?.section?.menu?.location?.isOrderingSpecialInstructionsEnabled;
  const isNcrSilverPayment = menuItem?.section?.menu?.location?.isNcrSilverPayment;
  const doordashDeliveryProhibited = cartFulfillmentType === 'delivery_fulfillment_type' && menuItem?.containsAlcohol && menuItem?.isDoordashDelivery;

  const [menuItemByLocationAndFulfillmentType] = useLazyQuery(
    menuItemByLocationAndFulfillmentTypeQuery,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const menuItemByLoc = res.menuItemByLocationAndFulfillmentType;
        if (menuItemByLoc) {
          dispatch(openAddToCartModal(menuItemByLoc.id, menuItemByLoc.menu.id));
        } else {
          dispatch(closeAddToCartModal());
          props.showSnackbarError(props.t('consumer.ordering.selected_item_availability_discrepancy'));
        }
      },
      onError: () => {
        dispatch(closeAddToCartModal());
      },
      variables: {
        fulfillmentType: cartFulfillmentType,
        locationId: cartLocationId,
        menuItemId: menuItem?.id,
      },
    },
  );

  useEffect(() => {
    // If location selection flow changes cart location from menu item location
    // Check if that menu item is available for that location and fulfillment type
    // If available open addToCartModal with new menu item
    // If not close addToCartModal
    if (cartIsNewLocation) {
      menuItemByLocationAndFulfillmentType();
    }
    // manually resync subscription query
    void getApolloClient(loadedClient => loadedClient.refetchQueries({ include: [sessionWithMenuItemCartQuery] }));
  }, [cartIsNewLocation, menuItemByLocationAndFulfillmentType]);

  useEffect(() => {
    if (props.modernLayout) return () => { };
    const modalContent = findElement('.MuiDialogContent-root');
    const modalPaperBody = findElement('.MuiDialog-paperScrollPaper');
    const nameElement = findElement('.pm-menu-item');

    const handleScroll = () => {
      if (nameElement && initialOffsetTop < nameElement.offsetTop && !isStuck) {
        setIsStuck(true);
      } else if (nameElement && initialOffsetTop >= nameElement.offsetTop && isStuck) {
        setIsStuck(false);
      }
    };

    modalContent.addEventListener('scroll', handleScroll, { passive: true });
    modalPaperBody.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      modalContent.removeEventListener('scroll', handleScroll);
      modalPaperBody.removeEventListener('scroll', handleScroll);
    };
  }, [isStuck, initialOffsetTop, props.modernLayout]);

  useEffect(() => {
    if (titleElement) {
      setInitialOffsetTop(titleElement.offsetTop);
    }
  }, [windowSize, titleElement]);

  const description = [menuItem.description, formatCurrency(menuItem.price, menuItem.currency)].filter(v => v).join(' - ');

  // custom snackbar for adding items to cart
  const addToCartSnackbar = () => (
    <Grid container>
      {menuItem.photos.length > 0 && (
        <Grid item style={{ height: '120px', objectFit: 'cover', width: '120px' }}>
          <img
            alt={menuItem.name}
            src={menuItem.photos[0].thumbnailUrl}
            style={{
              borderRadius: '4px',
              height: '100%',
              objectFit: 'cover',
              width: '100%',
            }}
          />
        </Grid>
      )}
      <Grid item>
        <Typography variant="body1" style={{ fontWeight: 'bold' }}>
          {menuItem.name}
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id="consumer.ordering.updated_order" defaultMessage="🎉 Updated Order!" />
        </Typography>
      </Grid>
    </Grid>
  );

  const calculateOutOfStock = extra => (
    extra?.outOfStock && (!props.menuItemCartIsScheduled ||
      !extra.outOfStockUntil ||
      (props.menuItemCartIsScheduled && Date.parse(props.menuItemCartScheduledAt) < Date.parse(extra.outOfStockUntil)))
  );

  const getExtraTitle = (extra, outOfStock, opacity) => (
    <React.Fragment>
      <span className={opacity && outOfStock ? props.classes.outOfStockOpacity : ''}>{formatExtraName(extra.name, extra.price && formatCurrency(extra.price, menuItem.currency))}</span>
      {outOfStock && <span className={props.classes.outOfStockLabel}><span className={opacity ? props.classes.outOfStockOpacity : ''}><FormattedMessage id="consumer.ordering.out_of_stock" defaultMessage="Out of Stock" /></span></span>}
    </React.Fragment>
  );

  const getModernExtraTitle = (extra, outOfStock, opacity) => (
    <div className={props.classes.modifierModernOption}>
      <span>{extra.name}</span>
      <span>
        {outOfStock ? (
          <span className={props.classes.outOfStockLabel}><span className={opacity ? props.classes.outOfStockOpacity : ''}><FormattedMessage id="consumer.ordering.out_of_stock" defaultMessage="Out of Stock" /></span> </span>
        ) : (
          extra.price && <React.Fragment>{props.t('generic.plus')} {formatCurrency(extra.price, menuItem.currency)}</React.Fragment>
        )}
      </span>
    </div>
  );

  const mapSelectedModifierGroups = selectedModifierGroups => (
    selectedModifierGroups
      .map(selectedModifierGroup => ({
        menuItemModifierGroupId: selectedModifierGroup.menuItemModifierGroupId,
        selectedModifiers: selectedModifierGroup.selectedModifiers.map(({ menuItemModifierId, quantity, selectedModifierGroups: modifierSelectedModifierGroups }) => (
          modifierSelectedModifierGroups ? {
            menuItemModifierId,
            quantity: toInt(quantity) || 0,
            selectedModifierGroups: mapSelectedModifierGroups(modifierSelectedModifierGroups),
          } : {
            menuItemModifierId,
            quantity: toInt(quantity) || 0,
          }
        )).filter(sm => sm.quantity > 0),
      })).filter(smg => smg.selectedModifiers.length > 0)
  );

  const getModifierSubheading = (subheading, required) => (
    <div className={props.classes.modifierModernSubheading}>
      <span>{subheading}</span>
      <span className={!required && 'modifier-optional'}>{required ? 'Required' : 'Optional'}</span>
    </div>
  );

  const getModifierValues = variables => (isNestedModifiersEnabled ? {
    // Only submit modifier groups that have modifiers with a quantity greater than zero
    selectedExtraGroups: [],
    selectedExtras: [],
    selectedModifierGroups: mapSelectedModifierGroups(variables.selectedModifierGroups),
    // Only submit shared extra groups that have extras with a quantity greater than zero
    selectedSharedExtraGroups: [],
  } : {
    selectedExtraGroups: variables.selectedExtraGroups
      .map(selectedExtraGroup => ({
        menuItemExtraGroupId: selectedExtraGroup.menuItemExtraGroupId,
        selectedExtras: selectedExtraGroup.selectedExtras.map(({ extraId, quantity }) => ({
          extraId,
          quantity: toInt(quantity) || 0,
        })).filter(se => se.quantity > 0),
      })).filter(seg => seg.selectedExtras.length > 0),
    selectedExtras: variables.selectedExtras.filter(extra => extra && !!extra.menuItemExtraId),
    selectedModifierGroups: [],
    // Only submit shared extra groups that have extras with a quantity greater than zero
    selectedSharedExtraGroups: variables.selectedSharedExtraGroups
      .map(selectedSharedExtraGroup => ({
        selectedExtras: selectedSharedExtraGroup.selectedExtras.map(({ extraId, quantity }) => ({
          extraId,
          quantity: toInt(quantity) || 0,
        })).filter(se => se.quantity > 0),
        sharedExtraGroupId: selectedSharedExtraGroup.menuItemSharedExtraGroupId,
      })).filter(sseg => sseg.selectedExtras.length > 0),
  });

  const submitBtnText = props.t(props.selectedMenuItem.id === null ? 'consumer.ordering.add_to_order' : 'consumer.ordering.update_order');
  const submitBtnTitle = useCallback(
    subtotal => (props.modernLayout ?
      <React.Fragment><span>{submitBtnText}</span><span>{formatCurrency(subtotal, menuItem.currency)}</span></React.Fragment> :
      submitBtnText),
    [submitBtnText, props, menuItem.currency],
  );

  const { data: modGroupsData, loading: loadingModifierGroupsData } = useQuery(
    menuItemModifierGroupsQuery,
    {
      skip: !props.menuItem?.id,
      variables: {
        menuItemId: menuItem?.id,
      },
    },
  );

  if (loadingModifierGroupsData || !client) {
    return <Loading />;
  }

  const modifierGroupsData = modGroupsData?.menuItem?.modifierGroups;

  const getDefaultModifierValues = isNestedModifiersEnabled ? {
    selectedExtraGroups: [],
    selectedExtras: [],
    selectedModifierGroups: modifierGroupsData.map(modifierGroup => ({
      menuItemModifierGroupId: modifierGroup.id,
      selectedModifiers: getSelectedModifiers(modifierGroup, props.selectedMenuItem.selectedModifierGroups),
    })),
    selectedSharedExtraGroups: [],
  } : {
    selectedExtraGroups: menuItem.extraGroups.map(extraGroup => ({
      menuItemExtraGroupId: extraGroup.id,
      selectedExtras: getSelectedExtras(extraGroup, props.selectedMenuItem.selectedExtraGroups),
    })),
    selectedExtras: menuItem.extras.map(extra => ({
      menuItemExtraId: props.selectedMenuItem.selectedExtras.find(se => se.menuItemExtraId === extra.id) ? extra.id : null,
    })),
    selectedModifierGroups: [],
    selectedSharedExtraGroups: menuItem.sharedExtraGroups
      .filter(sharedExtraGroup => sharedExtraGroup?.isEnabled)
      .map(sharedExtraGroup => ({
        menuItemSharedExtraGroupId: sharedExtraGroup.id,
        selectedExtras: getSelectedSharedExtras(sharedExtraGroup, props.selectedMenuItem.selectedSharedExtraGroups),
      })),
  };

  return (
    <BasicForm
      data-cy="online_ordering_item_form"
      defaultValues={{
        message: props.selectedMenuItem.message,
        quantity: props.selectedMenuItem.quantity,
        selectedMenuItemSizeId: menuItem.sizes.length === 0 ? null : (props.selectedMenuItem.selectedMenuItemSizeId ? props.selectedMenuItem.selectedMenuItemSizeId : menuItem.sizes[0].id),
        ...getDefaultModifierValues,
      }}
      mutate={{
        mutation: props.selectedMenuItemMutation,
        onCompleted: () => {
          if (props.onSubmitComplete) {
            props.onSubmitComplete();
          }

          props.showSnackbar(addToCartSnackbar(), {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
            autoHideDuration: 1500,
            style: {
              marginTop: '45px',
            },
            variant: 'info',
          });

          props.closeModal();

          trackFacebookEvent('AddToCart');
          dispatch(setSelectedMenuItem(null));
        },
        onError: (err) => {
          props.closeModal();
          handleNotFound(err, dispatch);
        },
        toVariables: variables => ({
          menuItemCartId: props.menuItemCartId,
          selectedMenuItemId: props.selectedMenuItem.id,
          selectedMenuItemInput: {
            ...variables,
            menuItemId: menuItem.id,
            profileEventTracking: remindToTry,
            quantity: toInt(variables.quantity) || 0,
            // Only submit extra groups that have extras with a quantity greater than zero
            ...getModifierValues(variables),
          },
        }),
      }}
    >
      {({ values }) => {
        const subtotalValues = {
          ...values,
          posMenuItemPriceOverride: props.selectedMenuItem.posMenuItemPriceOverride,
        };

        const subtotal = calculateMenuItemSubtotal(menuItem, subtotalValues, isNestedModifiersEnabled, modifierGroupsData, client, false);
        const isQuantity = isValidQuantity(values, menuItem, isNestedModifiersEnabled, loadedModifierGroups);
        const isSelectedSize = menuItem.sizes.length === 0 || !!values.selectedMenuItemSizeId;
        const isSpecialInstructions = isValidSpecialInstructions(values.message, isNcrSilverPayment, menuItemCartSpecialInstructionsMaxLength);

        return (
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box className={classNames([props.classes.contentWrapper, props.classes.modernContentWrapper, 'selected-item-form'])}>
                {!props.modernLayout && menuItem.photos.length > 0 && (
                  <Typography gutterBottom>
                    {menuItem.photos.length > 0 && (
                      <CustomImg
                        alt={menuItem.name}
                        src={menuItem.photos[0].photoUrl}
                        style={{
                          borderRadius: '4px',
                          display: 'block',
                          height: 'auto',
                          maxHeight: '50vh',
                          objectFit: 'cover',
                          width: '100%',
                        }}
                      />
                    )}
                  </Typography>
                )}
                <Box className="menu-item-modifiers-container" style={{ padding: props.isMobile ? '0px 20px 0' : 0 }}>
                  {doordashDeliveryProhibited && (
                    <Box marginBottom={1}>
                      <Typography variant="caption">
                        <FormattedMessage id="consumer.ordering.delivery_prohibited" defaultMessage="This location does not currently allow delivery of alcoholic items" />
                      </Typography>
                    </Box>
                  )}
                  {
                    !props.modernLayout && (
                      <Box
                        className={classNames('pm-menu-item', props.classes.addToCartModalTitle, isStuck ? props.classes.addToCartModalTitleShadow : null)}
                        data-cy="add_to_order_modal_dish_name"
                      >
                        <AH typography component="h3" gutterBottom variant="h4">
                          {menuItem.name}
                        </AH>
                        {discrepancyMenuItemIds.length > 0 && (
                          <Typography variant="body2" className={classNames([props.classes.selectedDishDiscrepancyWording, props.modernLayout && props.classes.modernDiscrepancyWording])}>
                            <FormattedMessage
                              id="consumer.ordering.availability_discrepancy"
                              defaultMessage="Certain item(s) from your previous order are currently unavailable. Please make new selections below."
                            />
                          </Typography>
                        )}
                      </Box>
                    )
                  }
                  {
                    props.modernLayout && discrepancyMenuItemIds.length > 0 && (
                      <Typography variant="body2" className={props.classes.selectedDishDiscrepancyWording}>
                        <FormattedMessage
                          id="consumer.ordering.availability_discrepancy"
                          defaultMessage="Certain item(s) from your previous order are currently unavailable. Please make new selections below."
                        />
                      </Typography>
                    )
                  }
                  {!props.modernLayout && description && (
                    <Typography gutterBottom className={props.classes.addToCartModalDescription}>
                      {nl2br(description)}
                      <Divider />
                    </Typography>
                  )}
                  {menuItem.sizes.length > 0 && (
                    <RadioGroup
                      field="selectedMenuItemSizeId"
                      options={menuItem.sizes.map(size => ({
                        label: props.modernLayout ? getModernExtraTitle(size, false) : (size.price ? `${size.name} - ${formatCurrency(size.price, menuItem.currency)}` : size.name),
                        value: size.id,
                      }))}
                      title={props.modernLayout ? getModifierSubheading('Size', true) : 'Size'}
                      aria-label={'Size'}
                      validate={notBlankValidator}
                    />
                  )}
                  {isNestedModifiersEnabled ? (
                    <SelectedModifiers
                      calculateOutOfStock={calculateOutOfStock}
                      getExtraTitle={getExtraTitle}
                      modifierGroups={modifierGroupsData}
                    />
                  ) : (
                    <SelectedExtras
                      calculateOutOfStock={calculateOutOfStock}
                      getExtraTitle={props.modernLayout ? getModernExtraTitle : getExtraTitle}
                      menuItem={menuItem}
                      modernLayout={props.modernLayout}
                    />
                  )}
                  {isOrderingSpecialInstructionsEnabled && (
                    <TextAreaGroup
                      id="pm-special-requests-text-input"
                      field="message"
                      title="Special Requests"
                      data-cy="special_instruction"
                      characterLimit={isNcrSilverPayment ? menuItemCartSpecialInstructionsMaxLength : null}
                      validate={isNcrSilverPayment ? textLengthValidator({ max: menuItemCartSpecialInstructionsMaxLength, min: 0 }) : null}
                    />
                  )}
                </Box>
              </Box>
              <Box className={classNames([props.classes.addToCartModalFooter, 'add-to-cart-modal-footer'])}>
                <Grid container className={props.classes.addToCartModalFooterContentWrapper}>
                  <Grid item xs={5} style={{ 'align-items': 'center', display: 'flex' }}>
                    <TextFieldGroup
                      id="pm-item-quantity-input"
                      field="quantity"
                      showSteppers
                      showSteppersOnly={props.isMobile}
                      title="Quantity"
                      type="number"
                      maxValue={menuItem.orderingEventMaxAvailable}
                      maxValueNotice={props.t('consumer.ordering.max_quantity_reached')}
                      preventNegativeValues
                      validate={menuItem.orderingEventMaxAvailable ? minMaxValidator(1, menuItem.orderingEventMaxAvailable) : minOneValidator}
                      FormGroupProps={{
                        style: { width: '100%' },
                      }}
                      InputProps={{
                        inputProps: {
                          'aria-label': `Edit ${menuItem.name} quantity`,
                        },
                        readOnly: props.isMobile,
                      }}
                      endAdornmentButtonProps={{
                        'aria-label': `Add one ${menuItem.name}`,
                      }}
                      startAdornmentButtonProps={{
                        'aria-label': `Remove one ${menuItem.name}`,
                      }}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <SubmitGroup
                      block
                      className={classNames([props.classes.addToCartModalBtn, 'item-form-submit-btn'])}
                      disabled={!isQuantity || !isSelectedSize || !isSpecialInstructions || cartIsNewLocation || doordashDeliveryProhibited}
                      title={submitBtnTitle(subtotal)}
                      onClick={() => {
                        if (props.selectedMenuItem.id === null && props.menuItemCartType !== 'dine_in_cart_type') {
                          const featuredSectionOrigin = (featuredSectionData.menuItemIdChosen === menuItem.id ? 'featured_menu_section' : undefined);
                          createEvent({
                            eventableType: 'OnlineOrdering',
                            eventLabel: featuredSectionOrigin,
                            eventType: 'add_to_order_modal_button',
                          });
                        }
                      }}
                      data-cy="add_to_order_modal"
                      ButtonProps={{ 'data-vwo-tracker': 'add-to-cart' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        );
      }}
    </BasicForm>
  );
};

SelectedItemForm.defaultProps = {
  menuItemCartId: null,
  menuItemCartSpecialInstructionsMaxLength: null,
  menuItemCartType: null,
  onSubmitComplete: null,
  selectedMenuItem: {
    id: null,
    quantity: 1,
    selectedExtraGroups: [],
    selectedExtras: [],
    selectedModifierGroups: [],
    selectedSharedExtraGroups: [],
  },
};

SelectedItemForm.propTypes = {
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  menuItem: PropTypes.shape({
    extraGroups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      maxSelectionsCount: PropTypes.number,
      minSelectionsCount: PropTypes.number,
      multiSameSelection: PropTypes.bool,
      name: PropTypes.string,
    })).isRequired,
    extras: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      price: PropTypes.number,
    })).isRequired,
    id: PropTypes.number,
    name: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      price: PropTypes.number,
    })).isRequired,
  }).isRequired,
  menuItemCartId: PropTypes.number,
  menuItemCartSpecialInstructionsMaxLength: PropTypes.number,
  menuItemCartType: PropTypes.string,
  onSubmitComplete: PropTypes.func,
  restaurant: PropTypes.shape({
    isNestedModifiersEnabled: PropTypes.bool,
  }).isRequired,
  selectedMenuItem: PropTypes.shape({
    id: PropTypes.number,
    quantity: PropTypes.number.isRequired,
  }),
  selectedMenuItemMutation: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  windowSize: PropTypes.object.isRequired,
};

export default compose(
  withIntl,
  withRestaurant,
  withSnackbar,
  withStyles(menuItemCartFormStyles),
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
    windowSize,
  })),
)(SelectedItemForm);
